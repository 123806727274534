import Vue from 'vue';
import Component from 'vue-class-component';
import Router from 'vue-router';

import full_layout_pages from '@/router/full_layout_pages';
import account from '@/router/account';
import admin from '@/router/admin';
import entities from '@/router/entities';
import pages from '@/router/pages';
import customRoutes from '@/router/customRoutes';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate', // for vue-router 2.2+
]);

const Sidebar = () => import('@/core/layouts/sidebar.vue');
const Home = () => import('@/core/home/home.vue');
const Error = () => import('@/core/error/error.vue');

const Fullscreen = () => import('@/core/layouts/fullscreen.vue');

Vue.use(Router);

// prettier-ignore
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Fullscreen,
      children: [
        ...full_layout_pages,
        {
          path: '/forbidden',
          name: 'Forbidden',
          component: Error,
          meta: {error403: true}
        },
        {
          path: '/not-found',
          name: 'NotFound',
          component: Error,
          meta: {error404: true}
        },
      ]
    },
    {
      path: '/',
      component: Sidebar,
      children: [
        ...account,
        ...admin,
        entities,
        ...customRoutes,
        ...pages
      ]
    },


  ],
  scrollBehavior(to, from, savedPosition) {
    return {x: 0, y: 0}
  }

});

export default router;
