import { IWorkingHour } from '@/shared/model/working-hour.model';

export function findLatest(workingHours: IWorkingHour[]): IWorkingHour {
  if (workingHours == null || workingHours.length == 0) return null;
  const max = workingHours.reduce(function (prev, current) {
    if (prev.date == current.date) {
      return prev.start > current.start ? prev : current;
    } else {
      return prev.date > current.date ? prev : current;
    }
  });
  return max;
}
