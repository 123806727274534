import { IProject } from '@/shared/model/project.model';
import { IAppUser } from '@/shared/model/app-user.model';

export interface IWorkingHour {
  id?: number;
  start?: number | null;
  end?: number | null;
  note?: string | null;
  rate?: number | null;
  date?: Date | null;
  flag?: number | null;
  deletedTimestamp?: Date | null;
  createdTimestamp?: Date | null;
  project?: IProject;
  appUser?: IAppUser;
}

export class WorkingHour implements IWorkingHour {
  constructor(
    public id?: number,
    public start?: number | null,
    public end?: number | null,
    public note?: string | null,
    public rate?: number | null,
    public date?: Date | null,
    public flag?: number | null,
    public deletedTimestamp?: Date | null,
    public createdTimestamp?: Date | null,
    public project?: IProject,
    public appUser?: IAppUser
  ) {}
}
