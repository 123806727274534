






import { Component, Inject, Vue } from 'vue-property-decorator';
import AccountService from '@/account/account.service';

@Component
export default class BackBtn extends Vue {
  goBack() {
    this.$router.go(-1);
  }
}
