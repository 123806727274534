import account from '@/router/account';

const Register = () => import('@/account/register/register.vue');
const RegisterView = () => import('@/account/register-view/register-view.vue');
const Activate = () => import('@/account/activate/activate.vue');
const LoginView = () => import('@/account/login-view/login-view.vue');
const ResetPasswordInit = () => import('@/account/reset-password/init/reset-password-init.vue');
const ResetPasswordFinish = () => import('@/account/reset-password/finish/reset-password-finish.vue');
// prettier-ignore
export default [
  {
    path: '/',
    name: 'Home',
    component: LoginView
  },
  {
    path: '/register',
    name: 'RegisterView',
    component: RegisterView,
  },
  /*{
    path: '/register',
    name: 'Register',
    component: Register,
  },*/
  {
    path: '/account/activate',
    name: 'Activate',
    component: Activate,
  }, {
    path: '/account/reset/request',
    name: 'ResetPasswordInit',
    component: ResetPasswordInit,
  },
  {
    path: '/account/reset/finish',
    name: 'ResetPasswordFinish',
    component: ResetPasswordFinish,
  },

];
