import { Authority } from '@/shared/security/authority';

const GenUserManagementComponent = () => import('@/admin/user-management/user-management.vue');
const GenUserManagementViewComponent = () => import('@/admin/user-management/user-management-view.vue');
const GenUserManagementEditComponent = () => import('@/admin/user-management/user-management-edit.vue');
const GenDocsComponent = () => import('@/admin/docs/docs.vue');
const GenConfigurationComponent = () => import('@/admin/configuration/configuration.vue');
const GenHealthComponent = () => import('@/admin/health/health.vue');
const GenLogsComponent = () => import('@/admin/logs/logs.vue');
const GenMetricsComponent = () => import('@/admin/metrics/metrics.vue');

export default [
  {
    path: '/admin/user-management',
    name: 'GenUser',
    component: GenUserManagementComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/user-management/new',
    name: 'GenUserCreate',
    component: GenUserManagementEditComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/user-management/:userId/edit',
    name: 'GenUserEdit',
    component: GenUserManagementEditComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/user-management/:userId/view',
    name: 'GenUserView',
    component: GenUserManagementViewComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/docs',
    name: 'GenDocsComponent',
    component: GenDocsComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/health',
    name: 'GenHealthComponent',
    component: GenHealthComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/logs',
    name: 'GenLogsComponent',
    component: GenLogsComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/metrics',
    name: 'GenMetricsComponent',
    component: GenMetricsComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/configuration',
    name: 'GenConfigurationComponent',
    component: GenConfigurationComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
];
