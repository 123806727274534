import {Module} from 'vuex';
import axios from 'axios';
import {IFullProjectInfo} from '@/shared/model/fullProjectInfo.model';
import {IWorkingHour, WorkingHour} from '@/shared/model/working-hour.model';
import {formatStartEndTime, transformTimeToInt} from '@/utils/timeService';
import moment from 'moment';
import {findLatest} from '@/utils/storeHelper';
import {IProject} from '@/shared/model/project.model';

const baseProjectApiUrl = 'api/projects';
const customerBaseApiUrl = 'api/customers';
const baseWorkingHoursApiUrl = 'api/working-hours';
const LIMIT = 3;

export const dashboardStore: Module<any, any> = {
  state: {
    customers: [],
    runningProjects: [],
    closedProjects: [],
    currentlyWorking: null,
    startTimeStamp: null,
    timer: null,
    secondsWorking: 0,
    addTime: false,
    addTimeProject: null,
    loading: false,
    showEditWorkingHour: false, //needed for dialog
    editWorkingHour: null,
  },
  getters: {
    showEditWorkingHour: state => state.showEditWorkingHour,
    editWorkingHour: state => state.editWorkingHour,
    customers: state => state.customers,
    runningProjects: state => {
      const arr = state.runningProjects;
      //sort absteigend nach zuletzt verwendet
      arr.sort((a, b) => {
        const latestA = findLatest(a.workingHours);
        const latestB = findLatest(b.workingHours);
        if (latestA == null) return 1;
        if (latestB == null) return -1;
        if (latestA.date == latestB.date) {
          return latestA.start > latestB.start ? -1 : latestA.start > latestB.start ? 1 : 0;
        }
        return latestA.date > latestB.date ? -1 : latestA.date > latestB.date ? 1 : 0;
      });
      return arr;
    },
    closedProjects: state => state.closedProjects,
    runningProjectById: state => id => {
      return state.runningProjects.find(project => project.project.id === id);
    },
    runningProjectsForCustomer: state => customerId => {
      return state.runningProjects.filter(project => project.project.customer.id === customerId);
    },
    customersWithRunningProjects: state => {
      const customers = state.runningProjects.map(item => item.project.customer);
      return customers.filter(
        (value, index, self) => customers.map(item => item.id).indexOf(value.id) === index
      );
    },
    isCurrentlyWorking: state => {
      if (state.currentlyWorking == null) return false;
      return state.currentlyWorking.end == null;
    },
    currentlyWorking: state => state.currentlyWorking,
    checkCurrentProjectWorking: (state, getters) => projectId => {
      if (!getters.isCurrentlyWorking) {
        return false;
      }
      const currentlyWorkingTime: IWorkingHour = getters.currentlyWorking;
      return currentlyWorkingTime.project.id === projectId;
    },
    startTimeStamp: state => state.startTimeStamp,
    secondsWorking: state => state.secondsWorking,
    addTime: state => state.addTime,
    addTimeProject: state => state.addTimeProject,
    isLoading: state => state.loading,
  },
  mutations: {
    setShowEditWorkingHour(state, val) {
      state.showEditWorkingHour = val;
    },
    setEditWorkingHour(state, val) {
      state.editWorkingHour = val;
    },
    setLoading(state, val) {
      state.loading = val;
    },
    startTimer(state) {
      if (state.currentlyWorking == null
        || state.currentlyWorking.start == null
        || state.timer !== null) {
        //quit if nothing to do
        return;
      }
      if (state.startTimeStamp == null) {
        state.startTimeStamp = moment(formatStartEndTime(state.currentlyWorking.start), 'HH:mm');
      }
      state.timer = setInterval(() => {
        if (state.startTimeStamp) {
          const now = moment();
          const start = moment(state.startTimeStamp);
          state.secondsWorking = now.diff(start, 'seconds');
        }
      }, 1000);
    },
    stopTimer(state) {
      //  console.log('stop timer');
      clearInterval(state.timer);
      state.timer = null;
      state.secondsWorking = 0;
    },
    reset(state) {
      state.runningProjects = [];
      state.closedProjects = [];
    },
    updateCustomers(state, customers) {
      state.customers = customers;
    },
    setRunningProjects(state, projects) {
      state.runningProjects = projects;
    },
    updateClosedProject(state, projects) {
      state.closedProjects = projects;
    },
    setCurrentlyWorking(state, workingHour) {
      state.currentlyWorking = workingHour;
    },
    setStartTimestamp(state, time) {
      state.startTimeStamp = time;
    },
    setAddTime(state, addTime) {
      state.addTime = addTime;
    },
    setAddTimeProject(state, addTimeProject: IProject) {
      state.addTimeProject = addTimeProject;
    },
  },
  actions: {
    async retrieveCurrentlyWorkingOn({commit}) {
      try {
        const workingHour = (await axios.get('api/working-hours/currentlyWorkingOn')).data;
        commit('setCurrentlyWorking', workingHour);
      } catch (e) {
        console.error(e);
      }
    },
    async retrieveCustomers({commit}) {
      try {
        const customers = (await axios.get(customerBaseApiUrl)).data;
        commit('updateCustomers', customers);
      } catch (e) {
        console.error(e);
      }
    },
    async retrieveProjects({commit}) {
      try {
        const projects = (await axios.get(baseProjectApiUrl + '/full?closed=false&limit=' + LIMIT)).data;
        commit('setRunningProjects', projects);
      } catch (e) {
        console.error(e);
      }
    },
    async retrieveClosedProjects({commit}) {
      try {
        const projects = (await axios.get(baseProjectApiUrl + '/full?closed=true&limit=' + LIMIT)).data;
        commit('updateClosedProject', projects);
      } catch (e) {
        console.error(e);
      }
    },
    async startWorking({commit, dispatch}, project: IFullProjectInfo) {
      commit('setLoading', true);
      try {
        const now = new Date();
        const start = transformTimeToInt(now);
        const newWorkingHour = new WorkingHour();
        newWorkingHour.project = project.project;
        newWorkingHour.start = start;
        newWorkingHour.date = new Date();
        const res = await axios.post(`${baseWorkingHoursApiUrl}`, newWorkingHour);
        // console.log(res)
        commit('setStartTimestamp', now);
        commit('setCurrentlyWorking', res.data);
        commit('startTimer');
        dispatch('retrieveProjects');
        commit('setLoading', false);
      } catch (e) {
        console.error(e);
      }
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
    async endWorking({commit, dispatch, state}) {
      try {
        const now = new Date();
        const end = transformTimeToInt(now);
        const workingHourToUpdate = state.currentlyWorking;
        workingHourToUpdate.end = end;
        await dispatch('updateWorkingHour', workingHourToUpdate);

        commit('stopTimer');
        commit('setCurrentlyWorking', null);
        commit('setStartTimestamp', null);

        dispatch('retrieveProjects');
      } catch (e) {
        console.error(e);
      }
    },
    /**
     * updates working hour
     * @param workingHour workingHour which needs to be updated
     */
    async updateWorkingHour({}, workingHour): Promise<IWorkingHour> {
      try {
        const res = await axios.put(`${baseWorkingHoursApiUrl}/${workingHour.id}`, workingHour);
        return res.data;
      } catch (e) {
        console.error(e);
      }
      return null;
    },
    /**
     * updates working hour
     * @param workingHour workingHour which needs to be updated
     */
    async updateProject({}, project): Promise<IProject> {
      try {
        const res = await axios.put(`${baseProjectApiUrl}/${project.id}`, project);
        return res.data;
      } catch (e) {
        console.error(e);
      }
      return null;
    },
    async closeProject({commit, dispatch, getters, state}, projectId) {
      const project = getters.runningProjectById(projectId);
      if (!project) {
        return;
      }
      project.project.closed = true;
      const proj = await dispatch('updateProject', project.project);
      await dispatch('retrieveProjects');
      await dispatch('retrieveClosedProjects');
    },
    async updateWorkingNote({commit, dispatch, state}, note) {
      const workingHour = state.currentlyWorking;
      if (!workingHour) {
        return;
      }
      workingHour.note = note;
      //update
      const newWorkingHour = await dispatch('updateWorkingHour', workingHour);

      if (!newWorkingHour) {
        return;
      }
    },
    //
  },
};
