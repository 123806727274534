var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "btn bckBtn",
      attrs: { type: "button" },
      on: {
        click: function ($event) {
          return _vm.goBack()
        },
      },
    },
    [_c("font-awesome-icon", { attrs: { size: "lg", icon: "arrow-left" } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }