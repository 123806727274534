import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

const AppUser = () => import('@/entities/app-user/app-user.vue');
const AppUserUpdate = () => import('@/entities/app-user/app-user-update.vue');

const WorkingHour = () => import('@/entities/working-hour/working-hour.vue');
const WorkingHourUpdate = () => import('@/entities/working-hour/working-hour-update.vue');

const Customer = () => import('@/entities/customer/customer.vue');
const CustomerUpdate = () => import('@/entities/customer/customer-update.vue');

const Project = () => import('@/entities/project/project.vue');
const ProjectUpdate = () => import('@/entities/project/project-update.vue');
const ProjectDetails = () => import('@/entities/project/project.details.vue');

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'app-user',
      name: 'AppUser',
      component: AppUser,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'app-user/new',
      name: 'AppUserCreate',
      component: AppUserUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'app-user/:appUserId/edit',
      name: 'AppUserEdit',
      component: AppUserUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'working-hour',
      name: 'WorkingHour',
      component: WorkingHour,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'working-hour/new',
      name: 'WorkingHourCreate',
      component: WorkingHourUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'working-hour/:workingHourId/edit',
      name: 'WorkingHourEdit',
      component: WorkingHourUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer',
      name: 'Customer',
      component: Customer,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer/new',
      name: 'CustomerCreate',
      component: CustomerUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer/:customerId/edit',
      name: 'CustomerEdit',
      component: CustomerUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'project',
      name: 'Project',
      component: Project,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'project/new',
      name: 'ProjectCreate',
      component: ProjectUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'project/:projectId/edit',
      name: 'ProjectEdit',
      component: ProjectUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'project/:projectId/details',
      name: 'ProjectDetails',
      component: ProjectDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
