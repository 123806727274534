import Vue from 'vue';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '@/shared/date/filters';

export function transformTimeToInt(date: Date) {
  let intValue = date.getHours() * 60;
  intValue += date.getMinutes();
  return intValue;
}

export function getMinutes(value: number) {
  if (value) {
    const ret = value % 60;
    return ret;
  }
  return -1;
}

export function getHours(value: number) {
  if (value) return Math.trunc(value / 60);

  return -1;
}

const zeroPad = (num, places) => String(num).padStart(places, '0');

/**
 * formats the given start or end time to a Date-from
 * @param value
 */
export function formatStartEndTime(value: number) {
  if (value) {
    const mins = getMinutes(value);
    const hours = getHours(value);
    return zeroPad(hours, 2) + ':' + zeroPad(mins, 2);
  } else return '';
}

export function formatEuros(value: number) {
  if (value == null) {
    value = 0;
  }
  return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value);
}

export function centsToEuros(val: number) {
  if (!val) val = 0;
  return formatEuros(val / 100);
}

export function secondsDisplayString(value: number) {
  if (value != null) {
    const seconds = value % 60;
    let minutes = Math.trunc(value / 60);
    const hours = Math.trunc(minutes / 60);
    minutes = Math.trunc(minutes % 60);
    return zeroPad(hours, 2) + ':' + zeroPad(minutes, 2) + ':' + zeroPad(seconds, 2);
  }
  return '00:00:00';
}

/**
 * transforms time string to number
 * 12:00 ->
 * @param value
 */
export function transformStringToValue(value: string): number {
  const fields = value.split(':');
  let intValue = +fields[0] * 60;
  intValue += +fields[1];
  return intValue;
}

/**
 * HH:MM 24-hour format, optional leading 0
 * @param value
 */
export function isStringValid(value: string): boolean {
  if (!value) return true;
  const regex = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
  return regex.test(value);
}

export function formatDuration(value: number): string {
  if (value == null) return;
  //const hours = this.getHours(value);
  //const minutes = this.getMinutes(value);
  const num = value / 60;
  return (Math.round(num * 100) / 100).toFixed(2); //+ hours + " Std. " + minutes + " Min.";
}

export function durationToHours(value: number): string {
  if (value == null) return;
  const num = value / 60;
  return (Math.round(num * 100) / 100).toFixed(2);
}

export function formatDateShort(value) {
  if (value) {
    return dayjs(value).format(DATE_FORMAT);
  }
  return '';
}
