import axios from 'axios';

import { IWorkingHour } from '@/shared/model/working-hour.model';

const baseApiUrl = 'api/working-hours';
const baseFilterApiUrl = 'api/working-hours/filtered?';

//http://localhost:9000/api/working-hours/filtered?project=1501
//baseFilterApiUrlconst teststr1 = '/api/working-hours/filtered?customer=1151';
//const teststr2 = '/api/working-hours/filtered?project=1701';
//const teststrMoney = '/api/working-hours/filtered/calculated?project=1701';

export default class WorkingHourService {
  public retrieveForProject(projectId: number, limit = 30): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseFilterApiUrl}project=${projectId}&limit=${limit}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveFilterd(customerId: number, projectId: number, start, end, projectStatus): Promise<any> {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    let requestURL = baseFilterApiUrl;
    if (customerId) {
      requestURL += `customer=${customerId}&`;
    }
    if (projectId) {
      requestURL += `project=${projectId}&`;
    }
    if (start) {
      start = new Date(start);
      requestURL += `start=${start.toLocaleDateString('de-DE', options)}&`;
    }
    if (end) {
      end = new Date(end);
      requestURL += `end=${end.toLocaleDateString('de-DE', options)}&`;
    }
    if (projectStatus != null) {
      requestURL += `projectStatus=${projectStatus}&`;
    }
    return new Promise<any>((resolve, reject) => {
      axios
        .get(requestURL)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public find(id: number): Promise<IWorkingHour> {
    return new Promise<IWorkingHour>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: IWorkingHour): Promise<IWorkingHour> {
    return new Promise<IWorkingHour>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  //  @Inject('alertService') private alertService: () => AlertService;public saveAll(entities: IWorkingHour[]): Promise<IWorkingHour[]> {
  //  @Inject('alertService') private alertService: () => AlertService;  return new Promise<IWorkingHour[]>((resolve, reject) => {
  //  @Inject('alertService') private alertService: () => AlertService;    axios
  //  @Inject('alertService') private alertService: () => AlertService;      .post(`${baseApiUrl}/all`, entities)
  //  @Inject('alertService') private alertService: () => AlertService;      .then(res => {
  //  @Inject('alertService') private alertService: () => AlertService;        resolve(res.data);
  //  @Inject('alertService') private alertService: () => AlertService;      })
  //  @Inject('alertService') private alertService: () => AlertService;      .catch(err => {
  //  @Inject('alertService') private alertService: () => AlertService;        reject(err);
  //  @Inject('alertService') private alertService: () => AlertService;      });
  //  @Inject('alertService') private alertService: () => AlertService;  });
  //  @Inject('alertService') private alertService: () => AlertService;}

  public update(entity: IWorkingHour): Promise<IWorkingHour> {
    return new Promise<IWorkingHour>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: IWorkingHour): Promise<IWorkingHour> {
    return new Promise<IWorkingHour>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
