import Vue from 'vue';
import dayjs from 'dayjs';
import { centsToEuros, formatDateShort, formatDuration, formatEuros, formatStartEndTime, secondsDisplayString } from '@/utils/timeService';
import { PROJECT_PRIORITIES } from '@/entities/project/priority';

export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_FORMAT_ULTRASHORT = 'DD.MM.';
export const DATE_TIME_FORMAT = 'HH:mm - DD.MM.YYYY';

export const DATE_TIME_LONG_FORMAT = 'DD.MM.YYYYTHH:mm';

export function initFilters() {
  Vue.filter('formatDate', value => {
    if (value) {
      return dayjs(value).format(DATE_TIME_FORMAT);
    }
    return '';
  });
  Vue.filter('formatDateUltraShort', value => {
    if (value) {
      return dayjs(value).format(DATE_FORMAT_ULTRASHORT);
    }
    return '';
  });

  Vue.filter('formatDateShort', value => {
    return formatDateShort(value);
  });
  Vue.filter('formatMillis', value => {
    if (value) {
      return dayjs(value).format(DATE_TIME_FORMAT);
    }
    return '';
  });
  Vue.filter('duration', value => {
    if (value) {
      const formatted = dayjs.duration(value).humanize();
      if (formatted) {
        return formatted;
      }
      return value;
    }
    return '';
  });

  Vue.filter('displayString', value => {
    return formatStartEndTime(value);
  });
  Vue.filter('formatEuros', value => {
    return formatEuros(value);
  });
  Vue.filter('centsToEuro', value => {
    return centsToEuros(value);
  });
  Vue.filter('secondsDisplayString', value => {
    return secondsDisplayString(value);
  });
  Vue.filter('formatDuration', value => {
    return formatDuration(value);
  });
  Vue.filter('prioDisplayString', value => {
    const found = PROJECT_PRIORITIES.find(item => item.value == value);
    if (found) {
      return found.desc;
    }
    return '';
  });
  Vue.filter('prioColor', value => {
    const found = PROJECT_PRIORITIES.find(item => item.value == value);
    if (found && found.color) {
      return found.color;
    }
    return '';
  });
}
