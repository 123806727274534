import { Authority } from '@/shared/security/authority';

const Dashboard = () => import('@/dashboard/dashboard.vue');
const Stats = () => import('@/stats/stats.vue');

const ProjectTimeTracking = () => import('@/entities/project/project-time-tracking.vue');

export default [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/project/:projectId/time-tracking',
    name: 'ProjectTimeTracking',
    component: ProjectTimeTracking,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/stats',
    name: 'Stats',
    component: Stats,
    meta: { authorities: [Authority.USER] },
  },
];
