import { Module } from 'vuex';

export const headerStore: Module<any, any> = {
  state: {
    pageTitle: null,
  },
  getters: {
    pageTitle: state => state.pageTitle,
  },
  mutations: {
    setPageTitle(state, val) {
      state.pageTitle = val;
    },
  },
  actions: {},
};
